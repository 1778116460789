<template>
    <div class="container-fluid">
        <Quote title="Bu yerda izohlarni ko'rishingiz mumkin"/>
        <GoBack next="Izohlar"/>

        <div ref="top"></div>
        <div class="row justify-content-center mb-0">
            <div class="col-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11 overflow-auto">
                <table class="table table-hover border">
                    <thead>
                    <tr>
                        <th class="col-1 id d-none d-xl-table-cell">
                            <input
                                v-model="searchComments.id"
                                @keyup.enter="searchCommentInfo"
                                type="number"
                                placeholder="Id"
                                style="width: 50px;border-radius: 5px;"
                            />
                        </th>
                        <th class="col name">
                            <input
                                v-model="searchComments.givenName"
                                @keyup.enter="searchCommentInfo"
                                type="text"
                                placeholder="Ismi"
                                style="width: 100px;border-radius: 5px;"
                                class="inputs"
                            />
                        </th>
                        <th class="col surname d-none d-md-table-cell">
                            <input
                                v-model="searchComments.familyName"
                                @keyup.enter="searchCommentInfo"
                                type="text"
                                placeholder="Familiyasi"
                                style="width: 100px;border-radius: 5px;"
                            />
                        </th>
                        <th class=" surname d-none d-xl-table-cell">Rasmi</th>
                        <th class="col-1 id d-none d-xl-table-cell">
                            <select
                                v-model="searchComments.isApprove"
                                class="mb-2"
                                @change="searchCommentInfo"
                                style="width: 110px;border-radius: 5px;"
                            >
                                <option value="null">Barchasi</option>
                                <option value="true">Chop etilgan</option>
                                <option value="false">Kutilmoqda</option>
                            </select>
                        </th>
                        <th class="col status d-none d-sm-table-cell d-table-cell ">Yaratilgan sana</th>
                        <th class="col status d-table-cell">Tasdiqlash</th>
                        <th class="col-2 comment d-none d-lg-table-cell">Izoh</th>
                        <b-th class="table-icons" style="width: 100px">
                            <b-button
                                v-b-tooltip.hover
                                title="Izlash"
                                class="border-0 p-0"
                                style="background-color: #E5E5E5; font-size:1.6vw;"
                            >
                                    <span>
                                      <img
                                          @click="searchCommentInfo"
                                          style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                          src="../assets/search-icon.png"
                                          alt=""
                                      >
                                    </span>
                            </b-button>
                            <b-button
                                v-b-tooltip.hover
                                title="Tozalash"
                                class="border-0 p-1 p-sm-0 ms-sm-3"
                                style="background-color: #E5E5E5; font-size:1.7vw;"
                            >
                                    <span>
                                      <img
                                          @click="resetSaleSearchInfo"
                                          style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                          src="../assets/eraser-icon.png"
                                          alt=""
                                      >
                                    </span>
                            </b-button>
                        </b-th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        class="pl-3 pt-3 pb-3"
                        v-for="comment in getAllComments"
                        :key="comment.id"
                    >
                        <td class="d-none d-xl-table-cell">{{ comment.user.id }}</td>
                        <td>{{ comment.user.givenName }}</td>
                        <td class="d-none d-md-table-cell">{{ comment.user.familyName }}</td>
                        <td class="d-none d-lg-none d-xl-table-cell">
                            <div v-if="comment.image !== null">
                                <img
                                    :src="url + '/media/' + comment.image.filePath"
                                    alt="photo" class="img"
                                    style="width: 100px; height: auto; border-radius: 5px"
                                >
                            </div>
                            <div v-else>
                                <img
                                    src="../assets/avatar.png"
                                    alt="photo"
                                    style="width: 100px; height: auto; border-radius: 5px"
                                >
                            </div>
                        </td>
                        <td class="d-none d-xl-table-cell">
                            <div v-if="comment.isApprove" style="color: #765AF8">Chop etildi</div>
                            <div v-else style="color: red">Kutilmoqda</div>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <div>
                                 <span
                                     class="data ml-lg-2"
                                     v-if="comment.updatedAt"
                                 >
                                    {{ comment.updatedAt.slice(0, 10).split('-').reverse().join('-') }}
                                 </span>
                                <span class="ml-2" v-else>
                                    {{ comment.createdAt.slice(0, 10).split('-').reverse().join('-') }}
                                 </span>
                            </div>
                        </td>
                        <td class="d-table-cell">
                            <button
                                v-if="!comment.isApprove"
                                @click="isAgree(comment.id)"
                                type="button"
                                class="btn btn-primary agree-btn"
                            >
                                Chop etish
                            </button>

                            <button
                                v-else
                                @click="disagree(comment.id)"
                                type="button"
                                class="btn btn-danger agree-btn"
                            >
                                Rad etish
                            </button>
                        </td>
                        <td class="d-none d-lg-table-cell">{{ comment.text }}</td>
                        <td v-if="!comment.isDeleted">
                            <router-link
                                :to="'/edit-comment/' + comment.id"
                            >
                                <img class="pen mr-2" src="../assets/pen.png" alt="pencil-icon">
                            </router-link>
                            <img
                                @click="showModal(comment.id)"
                                class="p-0 ms-sm-3 trash"
                                src="../assets/trash.png"
                                alt="trash-icon"
                            >
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div>
                    <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Ushbu izohni rostdan ham o'chirmoqchimisiz?</h3>
                        </div>
                        <b-button
                            class="mt-3"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="sm"
                            @click="hideErrorModal"
                        >
                            Yo'q
                        </b-button>
                        <b-button class="mt-3 float-end" style="width: 100px" variant="btn btn-danger" size="md"
                                  @click="deleteMyComment">
                            Ha
                        </b-button>
                    </b-modal>
                </div>

                <div>
                    <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Izoh o'chirildi</h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideErrorModal"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>

            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>

        <div>
            <b-pagination
                v-if="getAllCommentsTotal > 19"
                v-model="currentPage"
                pills
                align="center"
                :per-page="20"
                :total-rows="getAllCommentsTotal"
                :click-handler="fetchAdminCommentsByPage"
                @input="fetchAdminCommentsByPage"
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "../components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CourseStudentPage",
    components: {
        Quote,
        GoBack
    },
    data() {
        return {
            show: true,
            url: process.env.VUE_APP_API_URL,
            commentId: null,
            currentPage: 1,
            searchComments: {
                id: null,
                givenName: '',
                familyName: '',
                isApprove: null,
                page: 1
            },
            options: [
                {text: 'Barchasi', value: null},
                {text: 'Chop etilgan', value: true},
                {text: 'Kutulmoqda', value: false}
            ],
        }
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchAdminComments(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions([
            'fetchAllComments',
            'putIsApprove',
            'makeHasSeen',
            'mediaFetch',
            'deleteComment',
            'fetchAdminComments',
            'searchCommentsInfo'
        ]),
        fetchAdminCommentsByPage() {
            this.$router.push('/comments/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()
        },
        searchCommentInfo() {
            this.show = true
            this.searchCommentsInfo(this.searchComments)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSaleSearchInfo() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.searchCommentInfo()
        },
        isAgree(value) {
            this.putIsApprove({
                id: value,
                isApprove: true
            })
                .then(() => {
                    this.searchCommentsInfo(this.searchComments)
                })
        },
        disagree(value) {
            this.putIsApprove({
                id: value,
                isApprove: false
            })
                .then(() => {
                    this.searchCommentsInfo(this.searchComments)
                })
        },
        deleteMyComment() {
            this.deleteComment(this.commentId)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    setTimeout(this.hideErrorModal, 3000)
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                    this.fetchAllComments()
                })
        },
        showModal(id) {
            this.commentId = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-delete'].hide()
            this.$refs['modal-success'].hide()
        },
    },
    computed: {
        ...mapGetters(['getAllComments', 'getImages', 'getAllCommentsTotal'])
    },
    mounted() {
        this.show = true
        this.makeHasSeen()
        this.mediaFetch()
        this.show = false
    }
}
const tr = document.querySelectorAll('tr');
for (let i = 0; i < tr.length; i++) {
    tr[i].setAttribute('class', 'align-top');
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

img {
    width: 20px;
    height: 20px;
    margin-bottom: 1rem;
}

.trash {
    cursor: pointer;
}

.agree-btn {
    font-size: 12px;
    font-family: Comfortaa, cursive;
    padding: 5px;
}

.row {
    margin-top: 111px;
    margin-bottom: 111px;
}

table {
    margin-bottom: 50px;
}

input::placeholder {
    padding: 0.5rem;
    font-size: 12px;
}

input {
    border: 1px solid black;
    height: 25px;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

select {
    box-shadow: 0 0 0 1px black;
    padding-left: 10px;
    height: 25px;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: left;

    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

tbody tr {
    height: 60px;
    vertical-align: text-top;
}

table tr select {
    margin-right: 10px;
    border: none;
}

.id {
    width: 98px;
    height: 56px;
    line-height: 56px;
}

.name {
    width: 111px;
    height: 56px;
    line-height: 56px;
}

.comment {
    height: 56px;
    line-height: 56px;
}

.surname {
    width: 129px;
    height: 56px;
    line-height: 56px;
}

.status {
    width: 170px;
    height: 56px;
    line-height: 56px;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;

    background-color: #FFFFFF;
    color: #000000;
}

input {
    border: 1px solid black;
}
</style>
